<template>
  <div>
    <orders-filter @filter="getOrdersList"/>
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-text-field
          v-model="ordersSearch"
          :prepend-inner-icon="mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getOrdersList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ordersList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="ordersListTotal"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :mobile-breakpoint="0"
          height="80vh"
          fixed-header
          dense
        >
          <template v-slot:item.document="{ item }">{{ item.nmdos }}: {{ item.obrano }}</template>
          <template v-slot:item.dataobra="{ value }">{{ formatDate(value) }}</template>
          <template v-slot:item.nome="{ item }">
            {{ item.nome }}
            <div class="text-caption">{{ `${$t('shortNumber')} ${item.no}`}}</div>
          </template>
          <template v-slot:item.u_stcorte="{ item, value, header }">
            <order-list-item
              v-if="item.lines_cut_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_cut_done"
            />
          </template>
          <template v-slot:item.u_stlamina="{ item, value, header }">
            <order-list-item
              v-if="item.lines_laminate_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_laminate_done"
            />
          </template>
          <template v-slot:item.u_stinter="{ item, value, header }">
            <order-list-item
              v-if="item.lines_cut_int_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_cut_int_done"
            />
          </template>
          <template v-slot:item.u_stdecor="{ item, value, header }">
            <order-list-item
              v-if="item.lines_cut_dec_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_cut_dec_done"
            />
          </template>
          <template v-slot:item.u_stselag="{ item, value, header }">
            <order-list-item
              v-if="item.lines_sealing_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_sealing_done"
            />
          </template>
          <template v-slot:item.u_staresta="{ item, value, header }">
            <order-list-item
              v-if="item.lines_edge_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_edge_done"
            />
          </template>
          <template v-slot:item.u_stentalh="{ item, value, header }">
            <order-list-item
              v-if="item.lines_notch_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_notch_done"
            />
          </template>
          <template v-slot:item.u_stfuro="{ item, value, header }">
            <order-list-item
              v-if="item.lines_hole_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_hole_done"
            />
          </template>
          <template v-slot:item.u_sttemp="{ item, value, header }">
            <order-list-item
              v-if="item.lines_tempering_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_tempering_done"
            />
          </template>
          <template v-slot:item.u_stfoscag="{ item, value, header }">
            <order-list-item
              v-if="item.lines_matte_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_matte_done"
            />
          </template>
          <template v-slot:item.u_stav="{ item, value, header }">
            <order-list-item
              v-if="item.lines_av_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_av_done"
            />
          </template>
          <template v-slot:item.u_stku="{ item, value, header }">
            <order-list-item
              v-if="item.lines_ku_count > 0"
              @click-item="clickItem(item.bostamp, header.value)"
              @click-revert="clickRevert(item.bostamp, header.value)"
              :count="item.rows.reduce((previousValue, row) => previousValue + row.partes, 0)"
              :done="item.lines_ku_done"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <orders-caption/>
    <order-list-process v-if="processDialog" @success="getOrdersList"/>
    <order-revert v-if="revertDialog" @success="getOrdersList"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiMagnify } from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { ordersListHeaders } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.$tc('order', 2),
    };
  },
  mixins: [helpers],
  components: {
    OrdersCaption: () => import(/* webpackChunkName: "orders" */ '@/components/orders/OrdersCaption.vue'),
    OrdersFilter: () => import(/* webpackChunkName: "orders" */ '@/components/filters/OrdersFilter.vue'),
    OrderListProcess: () => import(/* webpackChunkName: "orders" */ '@/components/orders/OrderListProcess.vue'),
    OrderListItem: () => import(/* webpackChunkName: "orders" */ '@/components/orders/OrderListItem.vue'),
    OrderRevert: () => import(/* webpackChunkName: "orders" */ '@/components/orders/OrderRevert.vue'),
  },
  data: () => ({
    itemsPerPage,
    options: {},
    headers: ordersListHeaders,
    mdiMagnify,
  }),
  computed: {
    ...mapFields(['isLoading']),
    ...mapFields('orders', {
      revertDialog: 'dialogs.revert',
      processDialog: 'dialogs.process',
      activeBostamp: 'active.bostamp',
      activeType: 'active.type',
    }),
    ...mapFields('orders', ['ordersSearch', 'ordersList', 'ordersListTotal']),
  },
  watch: {
    options: {
      handler() {
        this.getOrdersList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('orders', ['fetchOrdersList']),
    async getOrdersList() {
      this.isLoading = true;

      await this.fetchOrdersList(this.options);

      this.isLoading = false;
    },
    clickItem(bostamp, type) {
      this.activeBostamp = bostamp;

      this.activeType = type;

      this.processDialog = true;
    },
    clickRevert(bostamp, type) {
      this.activeBostamp = bostamp;

      this.activeType = type;

      this.revertDialog = true;
    },
  },
};
</script>
