<template>
  <v-card elevation="24" class="mt-1">
    <v-card-title class="text-uppercase">{{ $t('caption') }}</v-card-title>
    <v-card-text>
      <ul>
        <li v-for="(item, index) in caption" :key="index" :class="`${item}--text`">
          {{ $t('wot.ordersCaption')[item] }}
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    caption: ['primary', 'warning', 'success'],
  }),
};
</script>
