<template>
  <div>
    <v-badge v-if="count > 0" :content="count" color="secondary" overlap>
      <v-btn icon :disabled="disabled" :color="iconColor" @click="$emit('click-item')">
        <v-icon>{{ mdiViewList }}</v-icon>
      </v-btn>
    </v-badge>
    <v-btn v-if="done > 0" icon color="error" @click="$emit('click-revert')">
      <v-icon>{{ mdiUndo }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mdiViewList, mdiUndo } from '@mdi/js';

export default {
  emits: ['click-item', 'click-revert'],
  props: {
    count: {
      type: Number,
      required: true,
    },
    done: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ mdiViewList, mdiUndo }),
  computed: {
    iconColor() {
      let color;

      if (this.done === 0) {
        color = 'primary';
      } else if (this.count === this.done) {
        color = 'success';
      } else {
        color = 'warning';
      }

      return color;
    },
  },
};
</script>
