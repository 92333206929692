<template>
  <v-dialog :value="true" width="1300" @click:outside="processDialog = false">
    <v-card>
      <v-card-title class="secondary white--text">{{ fields.title }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="rows"
            :loading="isLoading"
            :loading-text="$t('wot.loading')"
            :mobile-breakpoint="0"
            hide-default-footer
            disable-pagination
            item-key="bistamp"
            show-select
            dense
          >
            <template v-slot:item.measurements="{ item }">
              <div>{{ $t('width') }}: {{ $n(item.largura, 'meter') }}</div>
              <div>{{ $t('height') }}: {{ $n(item.altura, 'meter') }}</div>
            </template>
            <template v-slot:item.date="{ item }">
              <template v-if="item[fields.date] === '1900-01-01'">
                <v-icon color="error">{{ mdiClose }}</v-icon>
              </template>
              <template v-else>{{ formatDate(item[fields.date]) }}</template>
            </template>
            <template v-slot:item.operator="{ item }">{{ item[fields.operator] }}</template>
            <template v-slot:item.lobsauto="{ item }">
              <process-notes :item="item" class="mt-5" @success="$emit('success')"/>
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
              <v-simple-checkbox
                :value="item[fields.date] > 0 ? true : isSelected"
                @input="select($event)"
                :disabled="item[fields.date] > 0"
                :ripple="false"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="processDialog = false">{{ $t('aClose') }}</v-btn>
        <v-btn
          text
          color="primary"
          :disabled="selected.length === 0 || allDone"
          @click="clickSave"
        >
          {{ $t('aSave') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiClose, mdiContentSaveCheck } from '@mdi/js';
import { ordersActionHeaders } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  mixins: [helpers],
  emits: ['success'],
  components: {
    ProcessNotes: () => import(/* webpackChunkName: "notes" */ '@/components/orders/ProcessNotes.vue'),
  },
  data: () => ({
    headers: ordersActionHeaders,
    selected: [],
    mdiClose,
    mdiContentSaveCheck,
    tempValues: {},
  }),
  computed: {
    ...mapFields(['isLoading', 'alertType']),
    ...mapFields('orders', { processDialog: 'dialogs.process' }),
    ...mapGetters('orders', ['fields', 'rows', 'order', 'allDone']),
    selectedBistamps() {
      return this.selected.map(({ bistamp }) => bistamp);
    },
  },
  methods: {
    ...mapActions('orders', ['storeProcess']),
    async clickSave() {
      this.isLoading = true;

      await this.storeProcess({ bistamps: this.selectedBistamps });

      if (this.alertType === 'success') {
        this.$emit('success');

        this.processDialog = false;
      }

      this.isLoading = false;
    },
  },
};
</script>
