var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":true,"width":"1300"},on:{"click:outside":function($event){_vm.revertDialog = false}}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_vm._v(_vm._s(_vm.fields.revert))]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rowsRevert,"loading":_vm.isLoading,"loading-text":_vm.$t('wot.loading'),"mobile-breakpoint":0,"hide-default-footer":"","disable-pagination":"","item-key":"bistamp","show-select":"","dense":""},scopedSlots:_vm._u([{key:"item.measurements",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t('width'))+": "+_vm._s(_vm.$n(item.largura, 'meter')))]),_c('div',[_vm._v(_vm._s(_vm.$t('height'))+": "+_vm._s(_vm.$n(item.altura, 'meter')))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item[_vm.fields.date])))]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item[_vm.fields.operator]))]}},{key:"item.lobsauto",fn:function(ref){
var item = ref.item;
return [_c('process-notes',{staticClass:"mt-5",attrs:{"item":item},on:{"success":function($event){return _vm.$emit('success')}}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.revertDialog = false}}},[_vm._v(_vm._s(_vm.$t('aClose')))]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.selected.length === 0},on:{"click":_vm.clickSave}},[_vm._v(" "+_vm._s(_vm.$t('revert'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }