<template>
  <v-dialog :value="true" width="1300" @click:outside="revertDialog = false">
    <v-card>
      <v-card-title class="secondary white--text">{{ fields.revert }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="rowsRevert"
            :loading="isLoading"
            :loading-text="$t('wot.loading')"
            :mobile-breakpoint="0"
            hide-default-footer
            disable-pagination
            item-key="bistamp"
            show-select
            dense
          >
            <template v-slot:item.measurements="{ item }">
              <div>{{ $t('width') }}: {{ $n(item.largura, 'meter') }}</div>
              <div>{{ $t('height') }}: {{ $n(item.altura, 'meter') }}</div>
            </template>
            <template v-slot:item.date="{ item }">{{ formatDate(item[fields.date]) }}</template>
            <template v-slot:item.operator="{ item }">{{ item[fields.operator] }}</template>
            <template v-slot:item.lobsauto="{ item }">
              <process-notes :item="item" class="mt-5" @success="$emit('success')"/>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="revertDialog = false">{{ $t('aClose') }}</v-btn>
        <v-btn text color="primary" :disabled="selected.length === 0" @click="clickSave">
          {{ $t('revert') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiClose } from '@mdi/js';
import { ordersActionHeaders } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  mixins: [helpers],
  emits: ['success'],
  components: {
    ProcessNotes: () => import(/* webpackChunkName: "notes" */ '@/components/orders/ProcessNotes.vue'),
  },
  data: () => ({
    headers: ordersActionHeaders,
    selected: [],
    mdiClose,
  }),
  computed: {
    ...mapGetters('orders', ['fields', 'rowsRevert', 'order']),
    ...mapFields('orders', { revertDialog: 'dialogs.revert' }),
    ...mapFields(['isLoading', 'alertType']),
    selectedBistamps() {
      return this.selected.map(({ bistamp }) => bistamp);
    },
  },
  methods: {
    ...mapActions('orders', ['revertProcess']),
    async clickSave() {
      this.isLoading = true;

      await this.revertProcess({ bistamps: this.selectedBistamps });

      if (this.alertType === 'success') {
        this.$emit('success');

        this.revertDialog = false;
      }

      this.isLoading = false;
    },
  },
};
</script>
