var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('orders-filter',{on:{"filter":_vm.getOrdersList}}),_c('v-card',{staticClass:"mt-1",attrs:{"elevation":"24"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.mdiMagnify,"label":_vm.$t('aSearch'),"counter":"50","maxlength":"50","clearable":"","outlined":"","rounded":"","dense":""},on:{"change":_vm.getOrdersList},model:{value:(_vm.ordersSearch),callback:function ($$v) {_vm.ordersSearch=$$v},expression:"ordersSearch"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ordersList,"loading":_vm.isLoading,"loading-text":_vm.$t('wot.loading'),"options":_vm.options,"server-items-length":_vm.ordersListTotal,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage
        },"mobile-breakpoint":0,"height":"80vh","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.nmdos)+": "+_vm._s(item.obrano))]}},{key:"item.dataobra",fn:function(ref){
        var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDate(value)))]}},{key:"item.nome",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" "),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(((_vm.$t('shortNumber')) + " " + (item.no))))])]}},{key:"item.u_stcorte",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_cut_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_cut_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stlamina",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_laminate_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_laminate_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stinter",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_cut_int_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_cut_int_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stdecor",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_cut_dec_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_cut_dec_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stselag",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_sealing_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_sealing_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_staresta",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_edge_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_edge_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stentalh",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_notch_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_notch_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stfuro",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_hole_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_hole_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_sttemp",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_tempering_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_tempering_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stfoscag",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_matte_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_matte_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stav",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_av_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_av_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}},{key:"item.u_stku",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
        var header = ref.header;
return [(item.lines_ku_count > 0)?_c('order-list-item',{attrs:{"count":item.rows.reduce(function (previousValue, row) { return previousValue + row.partes; }, 0),"done":item.lines_ku_done},on:{"click-item":function($event){return _vm.clickItem(item.bostamp, header.value)},"click-revert":function($event){return _vm.clickRevert(item.bostamp, header.value)}}}):_vm._e()]}}])})],1)],1),_c('orders-caption'),(_vm.processDialog)?_c('order-list-process',{on:{"success":_vm.getOrdersList}}):_vm._e(),(_vm.revertDialog)?_c('order-revert',{on:{"success":_vm.getOrdersList}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }