<template>
  <v-card elevation="24">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="2">
              <validation-provider name="fechada">
                <v-select
                  v-model="fechada"
                  :items="fechadaOptions"
                  :prepend-inner-icon="fechadaIcon"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { mdiLockOutline, mdiLockOpenVariantOutline, mdiListStatus } from '@mdi/js';
import helpers from '@/resources/helpers';

export default {
  mixins: [helpers],
  emits: ['filter'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      fechadaOptions: [
        { value: false, text: this.$tc('fOpen', 2) },
        { value: true, text: this.$tc('fClosed', 2) },
        { value: null, text: this.$t('fAll') },
      ],
    };
  },
  computed: {
    ...mapGetters('orders', ['ordersFilter']),
    ...mapFields('orders', { fechada: 'filters.orders.fechada' }),
    fechadaIcon() {
      switch (this.fechada) {
        case true:
          return mdiLockOutline;
        case false:
          return mdiLockOpenVariantOutline;
        default:
          return mdiListStatus;
      }
    },
  },
  watch: {
    ordersFilter: {
      deep: true,
      handler() {
        this.$emit('filter');
      },
    },
  },
};
</script>
